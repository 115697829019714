<script setup lang="ts">
import { useCurrencyInput } from "vue-currency-input";

interface Props {
  modelValue: number;
}

const props = defineProps<Props>();
defineEmits(["update:modelValue"]);

defineOptions({
  inheritAttrs: true,
});

const { inputRef, setValue } = useCurrencyInput({
  currency: "BRL",
  hideCurrencySymbolOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  locale: "pt-BR",
  autoDecimalDigits: true,
  valueRange: { min: 0, max: 10000000 },
});

watch(
  () => props.modelValue,
  (value) => {
    setValue(value as number);
  },
);
</script>
<template>
  <input ref="inputRef" v-bind="$attrs" type="text" />
</template>
